import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ServicesSection from "./../components/ServicesSection";
import ReasonsSection from "./../components/ReasonsSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="Premier Cleaning Service in Salt Lake City"
        subtitle="Book Online Instantly or Call 801-960-3728"
        //subtitle="Focus on building your amazing service and we will do the rest. We grew more than 10,000 online businesses."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage="/images/cleaningtiger/livingroom.jpg"
        bgImageOpacity={1}
        textColor=""
      />


   
      <FeaturesSection
        title="How It Works"
        //subtitle="Build user friendly and modern dashboards using the latest tech. Now is the time with a best selling UI framework."
       // strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

        
          <ReasonsSection
        title="Easy and Dependable"
        subtitle="Booking your cleaning service is as simple and hassle-free as it gets."
       // strapline="Created with passion"
        size="md"
        //bgColor="bg-white"
        bgColor="bg-slate-100"

        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <ServicesSection
        title="Our Cleaning Services"
        //subtitle="Build user friendly and modern dashboards using the latest tech. Now is the time with a best selling UI framework."
       // strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        //bgColor="bg-slate-200"

       // bgImage=""
       bgImage="/images/cleaningtiger/services.jpg"

        bgImageOpacity={1}
        textColor=""
      />  
            <CtaSection
        //subtitle="Connect with book lovers across cities, across countries, and across continents. Start exchanging books in any language. "
        strapline=""
        size="md"
        bgColor="bg-slate-100"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/*
      eventually, want to add both of these 1) schools 2) reviews/comments
      <ClientsSection
        title="You're in good company"
        subtitle=""
        strapline="Lots of happy customers"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      

      <NewsletterSection
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      */}
    </>
  );
}

export default IndexPage;
