import React, { useState, useEffect } from 'react';
import styles from './BookingForm.module.css'; // Import CSS Module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
const roomPrices = {
  1: 125,
  2: 140,
  3: 160,
  4: 195,
  5: 230,
  6: 265,
  7: 300,
  8: 335,
  9: 370,
  10: 405,
  "10+": 500,
};

const bathroomPrices = {
  1: 20,
  2: 40,
  3: 60,
  4: 80,
  5: 100,
 // 6: 120,
 // 7: 140,
 // 8: 160,
 // 9: 180,
 // 10: 200,
  //"10+": 400,
};

const discounts = {
  "one-time": 1,
  "weekly": 0.8,
  "biweekly": 0.85,
  "monthly": 0.90,
};

const extraOptions = [
  { id: 'next-day', label: 'Next Day Booking Fee $75', price: 75 },

  { id: 'first-time', label: 'Deep Clean $95', price: 95 },
  { id: 'move', label: 'Move-In/Out $95', price: 95 },
  { id: 'oven-cleaning', label: 'Oven Cleaning $45', price: 45 },
  { id: 'fridge-empty', label: 'Inside an Empty Fridge $25', price: 25},
  { id: 'fridge-full', label: 'Inside an Full Fridge $45', price: 45},
  { id: 'second-kitchen', label: 'Second Kitchen $50', price: 50},
  { id: 'walls', label: 'Walls ($20 per room)', price: 20},
  { id: 'shed', label: 'Shed/Pool House $75', price: 75},
  { id: 'cabinets', label: 'Inside (already emptied) Cabinets $45', price: 45},
  { id: 'garage-sweep', label: 'Garage Sweep $20', price: 20 },

  { id: 'interior-windows', label: 'Interior Windows ($10/window)', price: 10},
  { id: 'balcony-sweep', label: 'Balcony Sweep $20', price: 20 },
  { id: 'laundry', label: 'Laundry - Wash & Fold $25/load', price: 25 },
  { id: 'pets', label: 'Pets at Home $25', price: 25 },

  //{ id: 'next-day', label: 'Next Day Booking Fee', price: 75 },



];



const BookingForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zipcode: '',
    date: '',
    time: '',
    extras: {},
    rooms: 1, //default value
    bathrooms: 1,
    frequency: 'monthly', // Default value for frequency
    discount: '',
  });

  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    setSubtotal(calculateAmount(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleExtraClick = (extraId) => {
    setFormData((prevData) => {
      const newExtras = { ...prevData.extras };
      if (newExtras[extraId]) {
        newExtras[extraId]++;
      } else {
        newExtras[extraId] = 1;
      }
      return { ...prevData, extras: newExtras };
    });
  };

  const handleExtraRemoveClick = (extraId) => {
    setFormData((prevData) => {
      const newExtras = { ...prevData.extras };
      if (newExtras[extraId] && newExtras[extraId] > 1) {
        newExtras[extraId]--;
      } else {
        delete newExtras[extraId];
      }
      return { ...prevData, extras: newExtras };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create FormData object
    const formDataGoogle = new FormData();
  
    // Replace "entry.xxxxxx" with your actual entry IDs
    formDataGoogle.append("entry.1254999635", formData.name); // Replace with your actual entry ID
    formDataGoogle.append("entry.744122637", formData.email); // Replace with your actual entry ID
    formDataGoogle.append("entry.1105787069", formData.phone); // Replace with your actual entry ID
    formDataGoogle.append("entry.1981015677", formData.city); // Replace with your actual entry ID
    formDataGoogle.append("entry.879665826", formData.zipcode); // Replace with your actual entry ID
    formDataGoogle.append("entry.576831906", formData.date); // Replace with your actual entry ID
    formDataGoogle.append("entry.1822690142", formData.time); // Replace with your actual entry ID
  
    formDataGoogle.append("entry.929487090", formData.rooms); // Replace with your actual entry ID
    formDataGoogle.append("entry.874671842", formData.bathrooms); // Replace with your actual entry ID
    formDataGoogle.append("entry.1174526844", formData.frequency); // Replace with your actual entry ID
    formDataGoogle.append("entry.1456749121", JSON.stringify(formData.extras)); // Replace with your actual entry ID
    formDataGoogle.append("entry.1275130720", formData.discount); // Replace with your actual entry ID

    formDataGoogle.append("entry.2073169407", subtotal/100); // Replace with your actual entry ID

    // Google Form URL
    const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLScbdijXGwDtxNt_gj0VIG4fi9RfRYsYAUhZmuGO3031yvmUOw/formResponse";
  
    // Submit data to Google Form
    try {
        await fetch(googleFormUrl, {
          method: "POST",
          body: formDataGoogle,
          mode: "no-cors",
        });
    
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
  };
  

  const calculateAmount = (data) => {
    const roomCount = data.rooms > 10 ? "10+" : data.rooms;
    const bathroomCount = data.bathrooms > 10 ? "10+" : data.bathrooms;
    
    const roomPrice = roomPrices[roomCount] || 0;
    const bathroomPrice = bathroomPrices[bathroomCount] || 0;
    
    const extrasPrice = Object.entries(data.extras).reduce((total, [extraId, count]) => {
      const extraOption = extraOptions.find((opt) => opt.id === extraId);
      return extraOption ? total + (extraOption.price * count) : total;
    }, 0);

    const baseAmount = roomPrice + bathroomPrice + extrasPrice;
    const discount = discounts[data.frequency] || 1;

    return Math.round(baseAmount * discount * 100); // Amount in cents
  };
  

  return (
    <div className={styles.container}>
      {isSubmitted ? (
        <div className={styles.confirmationPage}>
          <h1>Thank You!</h1>
          <p>Your booking has been successfully submitted. We will get back to you soon.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={styles.bookingForm}>
          {/* Your existing form fields here */}
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" placeholder="Name" onChange={handleInputChange} required />
          </div>
  
          <div className={styles.flexRow}>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" placeholder="Email" onChange={handleInputChange} required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phone">Phone</label>
              <input type="tel" name="phone" id="phone" placeholder="Phone" onChange={handleInputChange} required />
            </div>
          </div>
  
          <div className={styles.flexRow}>
            <div className={styles.formGroup}>
              <label htmlFor="city">Street Address, City</label>
              <input type="text" name="city" id="city" placeholder="Street Address, City" onChange={handleInputChange} required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="zipcode">Zip Code</label>
              <input type="text" name="zipcode" id="zipcode" placeholder="Zip Code" onChange={handleInputChange} required />
            </div>
          </div>
  
          <div className={styles.flexRow}>
            <div className={styles.formGroup}>
              <label htmlFor="date">Service Date</label>
              <input type="date" name="date" id="date" onChange={handleInputChange} required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="time">Service Time</label>
              <input type="time" name="time" id="time" onChange={handleInputChange} required />
            </div>
          </div>
  
          <div className={styles.flexRow}>
            <div className={styles.formGroup}>
              <label htmlFor="rooms">Number of Rooms</label>
              <input
                type="number"
                name="rooms"
                id="rooms"
                placeholder="Number of Rooms"
                onChange={handleInputChange}
                value={formData.rooms}
                min="1" // Minimum value
                max="6" // Maximum value
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="bathrooms">Number of Bathrooms</label>
              <input
                type="number"
                name="bathrooms"
                id="bathrooms"
                placeholder="Number of Bathrooms"
                onChange={handleInputChange}
                value={formData.bathrooms}
                min="1" // Minimum value
                max="10" // Maximum value
                required
              />
            </div>
          </div>

  
          <div className={styles.formGroup}>
            <label htmlFor="frequency">Frequency</label>
            <select name="frequency" id="frequency" onChange={handleInputChange} value={formData.frequency}>
              <option value="once">Once</option>
              <option value="weekly">Weekly</option>
              <option value="biweekly">Biweekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
  
          <div className={styles.extraOptions}>
        <label>Extras</label>
        {extraOptions.map((option) => (
          <div key={option.id} className={styles.extraOptionContainer}>
            <button
              type="button"
              className={`${styles.extraButton} ${formData.extras[option.id] ? styles.selected : ''}`}
              onClick={() => handleExtraClick(option.id)}
            >
              {option.label} {formData.extras[option.id] ? `(${formData.extras[option.id]})` : ''}
            </button>
            {formData.extras[option.id] && formData.extras[option.id] > 0 && (
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => handleExtraRemoveClick(option.id)}
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </button>
            )}
          </div>
        ))}
      </div>
        <div className={styles.flexRow}>
          <div className={styles.formGroup}>
              <label htmlFor="discount">Discount Code</label>
              <input type="text" name="discount" id="discount" placeholder="Code" onChange={handleInputChange} />
            </div>
        </div>
          <p className={styles.infoText}>Discount is not calculated in subtotal, but will be reflected in the receipt.</p>

          <div className={styles.formGroup}>
            <label>Subtotal: ${subtotal / 100}</label>
          </div>
  
          <button type="submit" className={styles.submitButton}>
            Submit Booking
          </button>
        </form>
      )}
    </div>
  );
  
};

export default BookingForm;
