import React from "react";
import Meta from "./../components/Meta";
import BookingSection from "./../components/BookingSection";
import CtaSection from "./../components/CtaSection";

function BookingPage(props) {
  return (
    <>
      <Meta title="Booking" />

      <BookingSection
        title="Tell Us About Your Home"
        subtitle="Get a quote in 60 seconds. If the price and availablity works for you book online instantly!"
       // strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        //bgColor="bg-slate-200"

        bgImage="/images/cleaningtiger/cleaningsupplies.jpg"
        bgImageOpacity={1}
        textColor="text-yellow-100"
      />  
           
    </>
  );
}

export default BookingPage;
