import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import BookingForm from './BookingForm';

function BookingSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor} 
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
          
        />
        <BookingForm 

        />
      </div>
    </Section>
  );
}

export default BookingSection;
