import React from "react";
import {
  AdjustmentsVerticalIcon,
  ChartPieIcon,
  GlobeAmericasIcon,
  BoltIcon,
  PuzzlePieceIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

import { FireIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Button from "./Button";
import { Link } from "./../util/router";

function FeaturesSection(props) {
  const features = [
    {
      title: "Customize Your Service",
      description:
        "Choose from our range of cleaning services. We offer flexible scheduling and tailor our services to meet your specific requirements.",
      imageUrl: "/images/cleaningtiger/1.png",
      icon: UsersIcon,
      iconColor: "pink",
    },

    {
      title: "Book Online",
      description:
        "Schedule a time that works for you. Our user-friendly booking system makes it easy to select the services you need and book at your convenience.",
        imageUrl: "images/cleaningtiger/3.png",
         icon: PuzzlePieceIcon,
      iconColor: "blue",
    },
    {
      title: "Enjoy a Pristine Space",
      description:
        "Our professional cleaners will arrive as scheduled and take care of everything, leaving your home or office sparkling clean. ",
        imageUrl: "images/cleaningtiger/2.png",
         icon: GlobeAmericasIcon,
      iconColor: "emerald",
    },


  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
        {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
                image={feature.imageUrl}
                index={index} // Pass index here

              />
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}


        </div>

          <div className="text-center">
            <Button
              component={Link}
              //to="/pricing"
              to="/booking"
              size="xl"
              endIcon={
                <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
              }
            >
              Book Your Cleaning!
            </Button>
          </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
