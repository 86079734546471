import React from "react";
import Meta from "./../components/Meta";
import ServicesSection from "./../components/ServicesSection";
import CtaSection from "./../components/CtaSection";

function ServicesPage(props) {
  return (
    <>
      <Meta title="Services" />

      <ServicesSection
        title="Our Cleaning Services"
        //subtitle="Build user friendly and modern dashboards using the latest tech. Now is the time with a best selling UI framework."
       // strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        //bgColor="bg-neutral-200"

       // bgImage=""
       //bgImage="/images/cleaningtiger/dust.jpg"
       bgImage="/images/cleaningtiger/services.jpg"


        bgImageOpacity={.8}
        textColor=""
      />  
            <CtaSection
        //subtitle="Connect with book lovers across cities, across countries, and across continents. Start exchanging books in any language. "
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default ServicesPage;
