import React, { useState } from "react";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { updateUser } from "./../util/db";

function OnboardingPage() {
  const router = useRouter();
  const auth = useAuth();

  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    bookGenres: "",
    country: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(auth.user.uid, formData);
      router.push("/dashboard");
    } catch (error) {
      console.error("Error updating user: ", error);
      // Handle error (e.g., show a message to the user)
    }
  };


  return (
    <div className="container max-w-md mx-auto py-12">
      <h1 className="text-2xl mb-4">Personalization</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="age">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="female">Non-binary</option>
            <option value="female">Prefer not to say</option>

            <option value="other">Other</option>
          </select>
        </div>
        <div>
          <label htmlFor="bookGenres">What genres of books do you like to read?</label>
          <input
            type="text"
            id="bookGenres"
            name="bookGenres"
            value={formData.bookGenres}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="country">Which country are you living in?</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button type="submit" className="w-full p-2 bg-yellow-500 text-white rounded">Submit</button>
      </form>
    </div>
  );
}

export default OnboardingPage;
