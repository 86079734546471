import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function ReasonsSection(props) {
  const reasons = [
    {
      title: "Satisfaction Guarantee",
      description: "description",
    },
    {
      title: "Trustworthy Cleaning Staff",
      description: "Schedule a time that works for you. Our user-friendly booking system makes it easy to select the services you need and book at your convenience.",
    },
    
    {
      title: "Flat Rates & No Surprises!",
      description: "Description",
    },
    {
      title: "Convenient Online Booking",
      description: "Description",
    },
      {
        title: "Cash-free Payment",
        description: "Description",
      },
      {
        title: "Seamless Communication",
        description: "Description",
      },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="space-y-4">
          {reasons.map((reason, index) => (
            <div
              className="p-5 bg-white rounded-xl text-center shadow-md transition ease-out duration-200 hover:shadow-lg"
              key={index}
            >
              <h4 className="text-lg font-bold mb-2 text-slate-800">{reason.title}</h4>
              {/*
              <p className="leading-relaxed text-slate-800">
                {reason.description}
              </p> */}
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ReasonsSection;
