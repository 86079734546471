import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        title="Get in touch"
        subtitle="Send us an email at contact@cleaningtiger.com or call/text 801-960-3728 and we will get back to you as soon as possible. Office Hours are 9:00 am - 5:00 pm."
        strapline="We reply in 24hrs"
        size="md"
        bgColor="bg-white"
        //bgImage=""
        bgImage="/images/cleaningtiger/dust.jpg"

        bgImageOpacity={0.6}
        textColor=""
      />
    </>
  );
}

export default ContactPage;
