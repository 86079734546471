import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
//import DashboardAdmin from "./DashboardAdmin"; // Import the DashboardAdmin component


function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        title="Dashboard"
        subtitle="To see what books are available to exchange, go to Account > Book Catalog"
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(DashboardPage);
