import React from "react";

function FeatureIcon2(props) {
  //const { color = "orange", size = "normal", image } = props;
  const { color = "orange", size = "normal", image, index } = props;

  const rotations = [3, 3, -4]; // Adjust rotation angles as needed

  const classes = {
    color: {
      orange: {
        outer: "bg-orange-300",
        inner: "bg-orange-700",
      },
      red: {
        outer: "bg-red-300",
        inner: "bg-red-700",
      },
      emerald: {
        outer: "bg-emerald-300",
        inner: "bg-emerald-700",
      },
      purple: {
        outer: "bg-purple-300",
        inner: "bg-purple-700",
      },
      blue: {
        outer: "bg-blue-300",
        inner: "bg-blue-700",
      },
      pink: {
        outer: "bg-pink-300",
        inner: "bg-pink-700",
      },
    },
    size: {
      normal: {
        wrap: "w-48 h-48 m-2",
        outer: "-m-2",
        inner: "",
        image: "w-44 h-44",
      },
      large: {
        wrap: "w-80 h-80 m-5",
        outer: "-m-5",
        inner: "-m-2",
        image: "w-80 h-80",
      },
    },
  };

  const rotationStyle = {
    transform: `rotate(${rotations[index % rotations.length]}deg)`,
  };
  return (
    <div
    className={
      `inline-flex items-center justify-center relative ${classes.size[size].wrap}` +
      (props.className ? ` ${props.className}` : "")
    }
  >


    

    <div
      className="text-white relative transform transition ease-out duration-200  group-hover:-rotate-5 inline-block"
      //style={rotationStyle}
    >
      {image ? (
        <img
          src={image}
          alt=""
          className={`rounded-2xl ${classes.size[size].image}`}
        />
      ) : (
        props.children
      )}
    </div>
  </div>

    
  );
}

export default FeatureIcon2;
