import React, { useState, useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";
//import { useNavigate } from 'react-router-dom'; // For navigation
import { history } from "./../util/router";

function HeroSection(props) {
  //const navigate = useNavigate(); // For navigation

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    rooms: "1",
    bathrooms: "1",
    frequency: "one-time",
  });

  const [price, setPrice] = useState(0);

  const roomPrices = {
    1: 125,
    2: 140,
    3: 160,
    4: 195,
    5: 230,
  //  6: 265,
  //  7: 300,
  //  8: 335,
   // 9: 370,
    //10: 405,
    //"10+": 500,
  };

  const bathroomPrices = {
    1: 20,
    2: 40,
    3: 60,
    4: 80,
    5: 100,
  //  6: 120,
  //  7: 140,
   // 8: 160,
  //  9: 180,
    //10: 200,
    //"10+": 400,
  };
  const discounts = {
    "one-time": 1,
    "weekly": 0.8,
    "bi-weekly": 0.85,
    "monthly": 0.90,
  };

  useEffect(() => {
    const roomPrice = roomPrices[formData.rooms] || 0;
    const bathroomPrice = bathroomPrices[formData.bathrooms] || 0;
    const discount = discounts[formData.frequency];

    const total = (roomPrice + bathroomPrice) * discount;
    setPrice(total);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataGoogle = new FormData();

    formDataGoogle.append("entry.2005620554", formData.firstName); // Replace with your actual entry ID
    formDataGoogle.append("entry.558878959", formData.lastName);  // Replace with your actual entry ID
    formDataGoogle.append("entry.1045781291", formData.email);     // Replace with your actual entry ID
    formDataGoogle.append("entry.1166974658", formData.rooms);     // Replace with your actual entry ID
    formDataGoogle.append("entry.1923475488", formData.bathrooms); // Replace with your actual entry ID
    formDataGoogle.append("entry.1672114490", formData.frequency); // Replace with your actual entry ID

    fetch("https://docs.google.com/forms/d/e/1FAIpQLSf1iAKVGBnI2-67eKeQCuCYnNjHDYA6EQnLkbJEuYIiNajKXw/formResponse", {
      method: "POST",
      body: formDataGoogle,
      mode: "no-cors",
    })
    .then(() => {
      console.log("Form submitted successfully!");
    })
    .catch((error) => {
      console.error("Error submitting form:", error);
    });

    history.push('/booking');

  };


  const isEmailFilled = formData.email.trim() !== '';


  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container flex flex-col lg:flex-row-reverse space-y-3 lg:space-y-0 text-center lg:text-left">
        <div className="lg:w-1/2 lg:self-center">
          <SectionHeader
            title={<h1 style={{ fontSize: '4rem', lineHeight: '1.2', marginTop: '0' }}>{props.title}</h1>}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
         
          <div className="flex flex-col sm:flex-row sm:items-center justify-center lg:justify-start space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
            {/*
            <Button
              component={Link}
              to="/auth/signup"
              size="xl"
              endIcon={<ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />}
            >
              Join us today
            </Button>
            <Button component={Link} to="/about" size="xl" variant="light">
              Learn more
            </Button>
            */}
          </div> 
        </div>
        <div className="lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center pb-12 md:pb-0">
          <div className="relative">
            <div className="absolute pattern-dots text-blue-100 top-0 left-0 w-32 h-48 md:h-96 transform -translate-y-12 -translate-x-16 -rotate-3" />
            <div className="absolute pattern-dots text-blue-100 bottom-0 right-0 w-32 h-48 md:h-96 transform translate-y-12 translate-x-16 rotate-3" />
           {/*
            <div className="absolute rounded-full top-0 right-0 w-20 h-20 bg-yellow-200 bg-opacity-50 -mt-12 -mr-12" />
            <div className="absolute rounded-xl bottom-0 left-0 w-20 h-20 bg-blue-200 bg-opacity-50 -mb-10 -ml-10 transform rotate-3" />
            
            Pricing Form */}
            {/* Pricing Form */}
            <div className="max-w-md mx-auto bg-slate-200 bg-opacity-50 p-8 rounded shadow-md">
            <h2 className="text-2xl font-semibold text-center mb-6"> Get a Quote Instantly</h2>

            <form onSubmit={handleSubmit}>
                {/* First Row: First Name, Last Name, Email */}
                <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="mb-4 flex-1">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4 flex-1">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4 flex-1">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                </div>

                {/* Second Row: Rooms, Bathrooms, Frequency */}
                <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="mb-4 flex-1">
                    <label htmlFor="rooms" className="block text-sm font-medium text-gray-700">Number of Rooms</label>
                    <select
                    id="rooms"
                    name="rooms"
                    value={formData.rooms}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                    {Object.keys(roomPrices).map((key) => (
                        <option key={key} value={key}>{key} room{ key > 1 ? 's' : '' }</option>
                    ))}
                    </select>
                </div>

                <div className="mb-4 flex-1">
                    <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">Number of Bathrooms</label>
                    <select
                    id="bathrooms"
                    name="bathrooms"
                    value={formData.bathrooms}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                    {Object.keys(bathroomPrices).map((key) => (
                        <option key={key} value={key}>{key} bathroom{ key > 1 ? 's' : '' }</option>
                    ))}
                    </select>
                </div>

                <div className="mb-4 flex-1">
                    <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">Cleaning Frequency</label>
                    <select
                    id="frequency"
                    name="frequency"
                    value={formData.frequency}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                    <option value="one-time">One-time</option>
                    <option value="weekly">Weekly</option>
                    <option value="bi-weekly">Bi-weekly</option>
                    <option value="monthly">Monthly</option>
                    </select>
                </div>
                </div>

                {/* Calculated Price */}
                <div className="mb-4">
            
                <label className="block text-sm font-medium text-gray-700">Estimated Price</label>
                <div className="mt-1 text-lg font-semibold">{`$${price.toFixed(2)}`}</div>
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit" //className={styles.submitButton}
                     // change to "button" since the Link handles the navigation
                    className="w-full bg-yellow-700 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Book in 60 Seconds!
                  </button>
             
                </div>
            </form>
            </div>

          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
