import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function ServicesSection(props) {
  const features = [
    {
      title: "Standard Cleaning",
      description: "Life’s busy, and keeping up with daily chores can be a challenge. Our Standard Cleaning service takes the stress out of routine cleaning. We focus on the high-traffic areas of your home—like your kitchen and bathrooms—handling dust, dirt, and spills with precision. Think of it as your home’s regular tune-up, keeping things fresh and inviting. Ideal for maintaining a pristine environment between deep cleanings, so you can enjoy more time doing what you love.",
      iconColor: "pink",
    },
    {
      title: "Deep Cleaning",
      description: "Rediscover the joy of a truly clean home with our Deep Cleaning service. Perfect for spaces that haven’t seen a professional touch in a while, this deep dive tackles every nook and cranny. We go beyond the surface to eliminate stubborn grime from baseboards, doorknobs, and those tricky corners. It’s like a makeover for your home, ensuring a thorough cleanse that leaves your space feeling new and revitalized.",
      iconColor: "blue",
    },
    {
      title: "Move In/Out Cleaning",
      description: "Moving is a major life event, and our Move-in/Move-out service ensures that cleanliness is one less thing to worry about. Whether you’re saying goodbye to your old home or preparing to welcome new occupants, we handle the heavy lifting. From scrubbing high-touch surfaces to cleaning hidden spaces like vents and closets, our team ensures every detail is covered, giving you peace of mind during your move.",
      iconColor: "emerald",
    },
    {
      title: "Additional Services",
      description: "Looking for more specialized cleaning? We offer a range of additional services to meet your needs, interior window washing, home organization, and inside appliance cleaning (like your fridge and oven). We can also assist with washing dishes, laundry, and folding clothes. If you have any questions or need something specific, don’t hesitate to give us a call.",
      iconColor: "emerald",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="bg-slate-200 p-5 rounded-2xl text-center shadow-md transition ease-out duration-200 hover:shadow-lg flex flex-col justify-end"
              key={index}
            >
              <div className="flex-1">
                <h4 className="text-lg font-bold mb-2 text-slate-800">{feature.title}</h4>
                <p className="leading-relaxed text-slate-800">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ServicesSection;
